import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Services = styled.section`
  padding: 2rem 0;
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  h2 {
    text-align: center;
    margin: auto;
    border-bottom: 2px solid #284778;
    width: 75%;
  }
  ul {
    @media (min-width: 800px) {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 66%;
      min-height: 22rem;
    }
    list-style: none;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0.4rem;
    gap: 0.4rem;
    li {
      background-color: #fcf6e8;
      box-sizing: border-box;
      border: 2px solid #faf1dd;
      border-radius: 8px;
      text-align: center;
      margin: 0;
      vertical-align: middle;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0;
      font-size: 0.7rem;
    }
  }
`

const AdditionalServices = () => {
  const data = useStaticQuery(graphql`
    query {
      airCon: file(relativePath: { eq: "air-con.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      brick: file(relativePath: { eq: "brick.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      elder: file(relativePath: { eq: "elder.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      urgent: file(relativePath: { eq: "emergency.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      fence: file(relativePath: { eq: "fence.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      wheelchair: file(relativePath: { eq: "wheelchair.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  return (
    <Services>
      <h2>Other Services</h2>
      <ul>
        <li>
          <GatsbyImage image={getImage(data.wheelchair)} alt={"wheelchair"} />
          Accessibility
        </li>
        <li>
          <GatsbyImage image={getImage(data.airCon)} alt={"air con"} />
          Aircon and heating
        </li>
        <li>
          <GatsbyImage image={getImage(data.brick)} alt={"bricks"} />
          Brick and wall services
        </li>
        <li>
          <GatsbyImage image={getImage(data.urgent)} alt={"man in door"} />
          Urgent assitance
        </li>
        <li>
          <GatsbyImage image={getImage(data.elder)} alt={"old man"} />
          Senior living modification
        </li>
        <li>
          <GatsbyImage image={getImage(data.fence)} alt={"fence"} />
          Fence building and repair
        </li>
      </ul>
    </Services>
  )
}

export default AdditionalServices
